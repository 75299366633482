import http from '../../../http/axiosConfig';
import mailTableBtnMixin from './mailTableBtnMixin';
export default {
  mixins: [mailTableBtnMixin],
  data() {
    return {
      search: '',
      tableSchemas: [],
    };
  },
  methods: {
    async getSchemas() {
      let page = this.pagination.page;
      let url = `Schema?page=${page}&limit=${this.pagination.rowsPerPage}&sortField=createAt&sortDesc=true`;
      let result = await http.get(url);
      if (result.data === null) {
        this.notifyError('Nome do Schema Incompleto ou Incorreto!');
        this.search = null;
      }
      let res = result.data.data ? result.data.data : result.data;
      let tableBody = [];
      if (res.length > 0) {
        res.forEach((inSchema) => {
          let schemaJson = JSON.parse(inSchema.jsonValue);
          let createAt = this.convertDate(inSchema.createAt);
          let createBy =
            inSchema.createBy && inSchema.createBy.split('@').shift();

          let Created = `${createAt} (${createBy})`;

          let updateAt = inSchema.updateAt ? this.convertDate(inSchema.updateAt) : '';
          let updateBy =
            inSchema.updateBy ? inSchema.updateBy.split('@').shift() : '';

          let LastUpdated = updateAt && updateBy ? `${updateAt} (${updateBy})` : '';

          tableBody.push({
            ...inSchema,
            Description: schemaJson.description,
            Created: Created,
            LastUpdated: LastUpdated,
          });
        });
      } else if (res) {
        let schemaJson = JSON.parse(res.jsonValue);
        let createAt = this.convertDate(res.createAt);
        let createBy = res.createBy && res.createBy.split('@').shift();

        let Created = `${createAt} (${createBy})`;

        let updateAt = this.convertDate(res.updateAt);
        let updateBy = res.updateBy && res.updateBy.split('@').shift();

        let LastUpdated = `${updateAt} (${updateBy})`;

        tableBody.push({
          ...res,
          Created: Created,
          Description: schemaJson.description,
          LastUpdated: LastUpdated,
        });
      }
      this.tableSchemas = tableBody;
    },
  },
};