<template>
	<div>
		<v-app-bar color="primary" dark app>
			<div
				class="d-flex align-center"
				style="cursor: pointer"
				@click="navigate"
			>
				<v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
				<v-toolbar-title>Simjob DESIGNER</v-toolbar-title>
			</div>
			<v-spacer></v-spacer>
			<v-tooltip bottom>
				<template v-slot:activator="{ on, attrs }">
					<v-icon v-bind="attrs" @click="logout" v-on="on"> mdi-logout </v-icon>
				</template>
				<span>Sair</span>
			</v-tooltip>
		</v-app-bar>
		<v-navigation-drawer v-model="drawer" app temporary>
			<v-list class="h-100">
				<!-- <v-list-item class="d-flex justify-center">
          <v-img
            alt="Logo"
            class="shrink"
            contain
            :src="require('../../assets/logo.svg')"
            transition="scale-transition"
            width="50%"
          />
        </v-list-item> -->
				<v-list-item>
					<span
						class="subtitle-1 font-weight-medium font-italic text-capitalize"
						>{{ valueUser.tenanty + " (Tenanty)" }}</span
					>
				</v-list-item>
				<v-list-item>
					<span
						class="subtitle-1 font-weight-medium font-italic text-capitalize"
						>{{ valueUser.user + " (User)" }}</span
					>
				</v-list-item>
			</v-list>
			<v-divider></v-divider>
			<v-list class="pa-2 mb-5">
				<v-list-item
					:to="route.path"
					v-for="(route, index) in routePath"
					:key="index"
					:disabled="route.disabled"
				>
					<v-list-item-icon>
						<v-icon v-text="route.icon"></v-icon>
					</v-list-item-icon>
					<v-list-item-content>
						<v-list-item-title v-text="route.name"></v-list-item-title>
					</v-list-item-content>
				</v-list-item>
			</v-list>
			<v-divider></v-divider>
			
			<v-list class="pa-2 mt-5">
				<Query/>
				<v-list-item
					:to="item.path"
					v-for="(item, index) in jsonItems"
					:key="index"
					:disabled="item.disabled"
				>
					<v-list-item-icon>
						<v-icon v-text="item.icon"></v-icon>
					</v-list-item-icon>
					<v-list-item-content>
						<v-list-item-title v-text="item.name"></v-list-item-title>
					</v-list-item-content>
				</v-list-item>

				
				
			</v-list>
			<v-footer class="justify-center pl-0 subtitle-2" inset app>
				<span class="mb-5" style="color: rgba(0, 0, 0, 0.6)">
					V.Client {{ $version }}
				</span>
				<p>
					<strong>
						&copy; Copyright
						{{ new Date().toLocaleDateString("pt-BR").slice(6, 10) }} -
						<a href="https://accist.com.br/" target="_blank">
							Accist
						</a></strong
					>
				</p>
			</v-footer>
		</v-navigation-drawer>
	</div>
</template>

<script>
	import setParamsClientMixin from "../../mixins/setParamsClientMixin";
	import Query from "../tables/Query/dialogs/showNewQuery.vue";

	export default {
		mixins: [setParamsClientMixin],
		data: () => ({
			showDailog: false,
			routePath: [
				{
					name: "Schemas",
					path: "/schemas",
					icon: "mdi-code-json ",
					disabled: false,
				},
				{ name: "Action", path: "/actions", icon: "mdi-play", disabled: false },
				{ name: "View", path: "/views", icon: "mdi-eye", disabled: false },
				{ name: "Analysis", path: "/analysis", icon: "mdi-view-parallel-outline", disabled: false },
				{ name: "Dashboard", path: "/dashboards", icon: "mdi-finance", disabled: false }
				// {
				//   name: 'Customs',
				//   path: '/',
				//   icon: 'mdi-clipboard-edit',
				//   disabled: true,
				// },
			],
			jsonItems: [
				//{ name: "Query", component: Query, path: "/schemas", icon: "mdi-database-edit", disabled: false },
				{ name: "Import", path: "/", icon: "mdi-file-import", disabled: true },
				{ name: "Export", path: "/", icon: "mdi-file-export", disabled: true },
			],
			drawer: false,
		}),

		components: {
			Query
		},

		methods: {
			navigate() {
				if (this.$route.name !== "Schemas") {
					this.$router.push({ name: "Schemas" });
				}
			},
			async logout() {
				await this.$auth.logout();
				this.$router.push({ name: "Login" });
			},
		},
	};
</script>

<style></style>
