import getSchemasMixin from './getSchemasMixin';
export default {
  mixins: [getSchemasMixin],
  data() {
    return {};
  },
  methods: {
    async putAtualiza() {
      await this.putSchemaJson();
      this.getSchemas();
    },

    async runMQL() {
      this.$forceUpdate();
      await this.runMQLFromJson();
    },
    async deleteAtualiza(item) {
      if(await this.$isConfirmeDelete(`Tem certeza que deseja excluir esse item. <strong>${item.description}</strong>`)){
        await this.deleteSchema(item);
        this.getSchemas();
      }
    },
    async paginationChangeHandler(value) {
      this.pagination.page = await value;
      this.getSchemas();
    },
    customSort: function (items, index, isDesc) {
      items.sort((a, b) => {
        if (index[0] == 'Created') {
          let created = 'createAt';
          if (!isDesc[0]) {
            return new Date(b[created]) - new Date(a[created]);
          } else {
            return new Date(a[created]) - new Date(b[created]);
          }
        } else if (index[0] == 'LastUpdated') {
          let updated = 'updateAt';
          if (!isDesc[0]) {
            return new Date(b[updated]) - new Date(a[updated]);
          } else {
            return new Date(a[updated]) - new Date(b[updated]);
          }
        }
        else {
          if (typeof a[index] !== 'undefined') {
            if (!isDesc[0]) {
              return a[index].toLowerCase().localeCompare(b[index].toLowerCase());
            }
            else {
              return b[index].toLowerCase().localeCompare(a[index].toLowerCase());
            }
          }
        }
      });
      return items;
    }
  }
};