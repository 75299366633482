export default {
  data() {
    return {
      valueUser: "",
    };
  },
  methods: {
    async getValueUser() {
      let params = await this.$auth.getParams();
      this.valueUser = params;
    },
  },
  async mounted() {
    await this.getValueUser();
  },
};
