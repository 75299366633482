<template>
  <v-app>
    <menu-app-nav />
    <v-container>
      <router-view />
    </v-container>
  </v-app>
</template>

<script>
import menuAppNav from "../components/menu/menuAppNav.vue";
export default {
  components: { menuAppNav },
  name: "HomePage",
};
</script>