<template >
    <v-dialog v-model="showNewQuery" max-width="90%">
      <show-new v-model="showNew"></show-new>
      <template v-slot:activator="{ on, attrs }">
        <v-list-item v-on="on" v-bind="attrs">
          <v-list-item-icon>
            <v-icon>mdi-database</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Query</v-list-item-title>
        </v-list-item>
      </template>

      <v-card content-class="card">
        <v-card-actions>
        <v-row class="mt-2">
          <v-spacer></v-spacer>
          <v-btn text fab color="red" @click="closeDialog()">
            <v-icon large>mdi-close</v-icon>
          </v-btn>
        </v-row>
      </v-card-actions>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="success" @click="runMQL()">
          <v-icon> mdi-play </v-icon>
          Executar
        </v-btn>
        <v-btn color="info" @click="exportCSV(jsonstrQuery)">
          <v-icon> mdi-file-export </v-icon>
          Exportar
        </v-btn>
          <v-btn color="secondary" @click="saveQueryAsView(stuffNew), showNew = true"> 
            <v-icon> mdi-plus </v-icon>
            Nova View </v-btn>
      </v-card-actions>
<!-- 
      <v-row>
        <v-col>
            <v-text-field
              label="name"
              outlined
              v-model="viewName"
            ></v-text-field>
        </v-col>
        <v-col>
            <v-text-field
              label="description"
              outlined
              v-model="viewDescription"
            ></v-text-field>
        </v-col>
      </v-row> -->
      

    <v-row class="dialog-query">
      <v-col cols="6">
        <v-jsoneditor 
          v-model="stuffNew"
          :options="options"
          height="500px"
        ></v-jsoneditor>
      </v-col>

      <v-col cols="6">
        <div id="vueapp">
        <template>
          <json-view :data="jsonstrQuery" />
        </template>
        </div>
      </v-col>
    </v-row>
      </v-card>
      
    </v-dialog>

</template>

<script>
import queryMainMixin from '../../../../mixins/tables/Query/queryMainMixin';
import viewMainMixin from '../../../../mixins/tables/Views/viewsMainMixin';
import showNew from '../../Views/dialogs/showNew.vue';

export default {
  mixins: [queryMainMixin, viewMainMixin],
  jsonstrQuery: '',
  props: ['value'],
  components: {
    showNew,
  },
  data() {
    return {
      jsonstrQuery: null,
    };
  },

  methods: {
    saveQueryAsView(stuffNew) {
      localStorage.removeItem('NewQuery');
      localStorage.setItem('NewQuery', JSON.stringify(stuffNew));
      this.$root.$refs.A.teste();
    }
  }
};
</script>

<style>
.dialog-query {
  padding:20px !important;
}
</style>