import http from '../../../http/axiosConfig';
import notifyConfigMixin from '../../notifyConfigMixin';

export default {
  mixins: [notifyConfigMixin],
  data() {
    return {
      schemaObject: null,

      showViewer: false,
      showEdit: false,
      showNewQuery: false,

      stuffViewer: null,
      stuffEdit: null,

      stuffNew: {},
      strongEntity: false,
      nameSchema: '',

      pagination: { pages: 0, rowsPerPage: 1000000000, page: 1 },

      options: {
        mode: "code",
        mainMenuBar: false,
      },
    };
  },
  methods: {
    convertDate(date) {
      return new Date(date)
        .toLocaleString('pt-BR')
        .slice(0, 16);
    },
    viewerDialog(item) {
      this.schemaObject = item;
      this.showViewer = true;
      this.stuffViewer = JSON.parse(item.jsonValue);
    },
    editDialog(item) {
      this.schemaObject = item;
      this.showEdit = true;
      this.stuffEdit = JSON.parse(item.jsonValue);
    },
    newDialog() {
      this.showNewQuery = true;
    },

    /**
     * Execute api/Schema/run-mql in API and returns json result from query
     * Input result in JSON Viewer (jsonstrQuery)
     */
    async runMQLFromJson() {
      let res = await http.post(`Schema/run-mql`, this.stuffNew);
      if (res.data) {
        this.notifySuccess('MQL executada com sucesso');
        this.jsonstrQuery = res.data.data;
        this.$forceUpdate();
      } else {
        this.notifyError(res);
      }
    },

    async downloadCSV(data) {
      const blob = new Blob(["\ufeff", data], { type: 'text/csv' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.setAttribute('hidden', '');
      a.setAttribute('href', url);
      a.setAttribute('download', 'download.csv');
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    },

    async objectToCsv(data) {
      const csvRows = [];
      const headers = Object.keys(data[0]);
      csvRows.push(headers.join(','));

      for (const row of data) {
        const values = headers.map(header => {
          const escaped = ('' + row[header]).replace(/"/g, '\\"');
          return `"${escaped}"`;
        });

        csvRows.push(values.join(','));
      }

      return csvRows.join('\n');
    },

    async exportCSV(data) {
      const csv = await this.objectToCsv(data);
      this.downloadCSV(csv);
    },
    async deleteSchema(item) {
      let itemId = item.id;
      return await http.delete('Schema', itemId);
    },
    closeDialog() {
      localStorage.removeItem('NewQuery');
      this.jsonstrQuery = null;
      if (this.showViewer) this.showViewer = false;
      if (this.showEdit) this.showEdit = false;
      if (this.showNewQuery) this.showNewQuery = false;
    },
  },
};
